import { createSlice } from "@reduxjs/toolkit";
import { MAIN_LAYOUT } from "../../utils/constants";

const layoutsSlice = createSlice({
  name: "layouts",
  initialState: {
    layout: MAIN_LAYOUT,
  
  },
  reducers: {
    updateLayout(state, action) {
      state.layout = action.payload.layout;

    },
  },
});

export const { updateLayout } = layoutsSlice.actions;
export const layoutsReducer = layoutsSlice.reducer;
