import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ADMIN_ACCOUNT_DATA,
  ADMIN_LOGIN_LAYOUT,
  MAIN_LAYOUT,
} from "../utils/constants";
import {useForgetPasswordMutation,updateForgetCredentials,updateLayout } from "../store";
import Alert from "./common/Alert";
export default function ForgetPasswordLayout() {
  const dispatch = useDispatch();
  const [ForgetPassword, resutls] = useForgetPasswordMutation();
  const { username,key, password,confirmation } = useSelector(({ forgetPasswordCredentials }) => {
    return forgetPasswordCredentials;
  });
  const onLayoutChange = (layout) => {
    dispatch(updateLayout({ layout }));
  };
  const handleUsernameChange = (event) => {
    dispatch(
      updateForgetCredentials({ state: event.target.name, value: event.target.value })
    );
  };
  const handleKeyChange = (event) => {
    dispatch(
      updateForgetCredentials({ state: event.target.name, value: event.target.value })
    );
  };

  const handlePasswordChange = (event) => {
    dispatch(
      updateForgetCredentials({ state: event.target.name, value: event.target.value })
    );
  };
  const handleConfirmationChange = (event) => {
    dispatch(
      updateForgetCredentials({ state: event.target.name, value: event.target.value })
    );
  
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    ForgetPassword({ username,key, password ,confirmation});
  };
  return (
    <div className="container flex flex-col space-y-6 mx-auto md:flex-row md:space-y-0 md:space-x-12">
      <div className="md:block md:w-1/2">
        <img src={ADMIN_ACCOUNT_DATA.image} alt="" />
      </div>

      <div className="flex flex-col items-center space-y-3 md:w-1/2">
        <div>
          <span
            onClick={() => onLayoutChange(MAIN_LAYOUT)}
            className="text-brightRed cursor-pointer back-btn hover:text-darkGray animate-pulse"
          >
            Retour au mode de connexion ?
          </span>
        </div>

        <div className="max-w-sm flex flex-col mx-auto py-6 px-4 rounded-3xl bg-brightRedLight space-y-3 shadow-2xl">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-3">
              <input
                type="text"
                name="username"
                placeholder="Nom d'utilisateur"
                value={username}
                onChange={handleUsernameChange}
                className="px-5 py-2 rounded-full focuse:outline-none md:"
              />
              <input
                type="text"
                name="key"
                value={key}
                onChange={handleKeyChange}
                placeholder="Code d'activation"
                className="px-5 py-2 rounded-full focuse:outline-none md:"
              />
              <input
                type="text"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Mot de passe"
                className="px-5 py-2 rounded-full focuse:outline-none md:"
              />
              <input
                type="text"
                name="confirmation"
                value={confirmation}
                onChange={handleConfirmationChange}
                placeholder="Confirmer mot de passe"
                className="px-5 py-2 rounded-full focuse:outline-none md:"
              />
              <button
                className="px-6 py-2 text-white rounded-full bg-brightRed hover:bg-gray-900"
                type="submit"
              >
                Vérifier
              </button>
            </div>
          </form>
          {resutls.error && <Alert data={resutls.error.data.errors} />}
          <div className="py-6 px-4 rounded-3xl bg-white hidden">
            <ul className="list-disc"></ul>
          </div>
        </div>

        <div className="flex flex-col items-center space-y-0">
          <span
            onClick={() => onLayoutChange(ADMIN_LOGIN_LAYOUT)}
            className="hover:text-brightRed cursor-pointer switch-login"
          >
            Connecter a mon compte!
          </span>
        </div>
      </div>
    </div>
  );
}
