import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { loginApi } from "./api/loginApi";
import { layoutsReducer, updateLayout } from "./slices/layouts";
import {
  adminCredentialsReducer,
  updateCredentials,
} from "./slices/adminCredentials";
import {
  forgetPasswordCredentialsReducer,
  updateForgetCredentials,
} from "./slices/forgetPasswordCredentials";

export const store = configureStore({
  reducer: {
    layouts: layoutsReducer,
    adminCredentials: adminCredentialsReducer,
    forgetPasswordCredentials: forgetPasswordCredentialsReducer,
    [loginApi.reducerPath]: loginApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(loginApi.middleware);
  },
});

setupListeners(store.dispatch);

export {
  useAdminConnectMutation,
  useStudentConnectMutation,
  useForgetPasswordMutation,
} from "./api/loginApi";
export { updateLayout, updateCredentials, updateForgetCredentials };
