import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const loginApi = createApi({
  reducerPath: "login",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://www.scholine.com",
  }),
  endpoints(builder) {
    return {
      adminConnect: builder.mutation({
        query: (body) => {
          return {
            url: "/api/users/v_1/signin",
            method: "POST",
            body,
          };
        },
      }),

      ForgetPassword: builder.mutation({
        query: (body) => {
          return {
            url: "/api/users/v_1/forgetPassword",
            method: "POST",
            body,
          };
        },
      }),

      studentConnect: builder.mutation({
        query: ({ key }) => {
          return {
            url: "/api/users/v_2/signin",
            method: "POST",
            body: {
              key,
            },
          };
        },
      }),
    };
  },
});

export const {
  useAdminConnectMutation,
  useStudentConnectMutation,
  useForgetPasswordMutation,
} = loginApi;

export { loginApi };
