import React from "react";
import { useDispatch } from "react-redux";
import { updateLayout } from "../store/slices/layouts";

export default function AccountTypeCard({ account }) {
  const dispatch = useDispatch();

  const onLayoutChange = () => {
    dispatch(updateLayout({ layout: account.layout }));
  };

  return (
    <div className="max-w-sm flex flex-col p-6 rounded-3xl bg-white space-y-3 shadow-2xl md:max-w-md md:w-1/2">
      <div>
        <img src={account.image} alt="" />
      </div>
      <h1 className="max-w-md text-2xl font-bold text-center text-darkGray md:text-left">
        {account.title}
      </h1>
      <p className="max-w-sm text-left text-darkGrayishBlue">{account.text}</p>
      <div className="flex justify-center md:justify-start">
        <button
          onClick={onLayoutChange}
          className="px-6 py-2 text-white rounded-full bg-brightRed hover:bg-gray-900"
        >
          Connecter
        </button>
      </div>
    </div>
  );
}
