import { createSlice } from "@reduxjs/toolkit";

const adminCredentialsSlice = createSlice({
  name: "adminCredentials",
  initialState: {
    username: "",
    password: "",
  },
  reducers: {
    updateCredentials(state, action) {
      state[action.payload.state] = action.payload.value;
    },
  },
});

export const { updateCredentials } = adminCredentialsSlice.actions;
export const adminCredentialsReducer = adminCredentialsSlice.reducer;
