import "./styles.css";
import React from "react";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

import {
  ADMIN_ACCOUNT,
  ADMIN_LOGIN_LAYOUT,
  FORGET_PASSWORD_LAYOUT,
  MAIN_LAYOUT,
  STUDENT_ACCOUNT,
  STUDENT_LOGIN_LAYOUT,
  TEACHER_LOGIN_LAYOUT,
} from "./utils/constants";

import MainLayout from "./components/MainLayout";
import AdminLayout from "./components/AdminLayout";
import TeacherLayout from "./components/TeacherLayout";
import StudentLayout from "./components/StudentLayout";
import ForgetPasswordLayout from "./components/ForgetPasswordLayout";
import Header from "./components/Header";

export default function App() {
  const { layout } = useSelector(({ layouts }) => {
    return layouts;
  });

  const token = Cookies.get("token");
  const account = Cookies.get("account");
  const domain = Cookies.get("domain");

  if (token) {
    if (account === ADMIN_ACCOUNT) {
      const url = process.env.REACT_APP_DEV_URL
        ? `http://127.0.0.1:4000/admin/annexes`
        : `${domain}/admin/annexes`;
      window.location.href = url;
    } else if (account === STUDENT_ACCOUNT) {
      const url = process.env.REACT_APP_DEV_URL
        ? `http://127.0.0.1:4000/notifications`
        : `${domain}/client/student/notifications`;
      window.location.href = url;
    }
  }

  let content;

  if (layout === MAIN_LAYOUT) {
    content = <MainLayout />;
  } else if (layout === ADMIN_LOGIN_LAYOUT) {
    content = <AdminLayout />;
  } else if (layout === TEACHER_LOGIN_LAYOUT) {
    content = <TeacherLayout />;
  } else if (layout === STUDENT_LOGIN_LAYOUT) {
    content = <StudentLayout />;
  } else if (layout === FORGET_PASSWORD_LAYOUT) {
    content = <ForgetPasswordLayout />;
  }

  return (
    <>
      <Header />
      {content}
    </>
  );
}
