import { createSlice } from "@reduxjs/toolkit";

const forgetPasswordCredentialsSlice = createSlice({
  name: "forgetPasswordCredentials",
  initialState: {
    username: '',
    key:'',
    password: '',
    confirmation: '',
  },
  reducers: {
   updateForgetCredentials (state, action) {
      state[action.payload.state] = action.payload.value;
    },
  },
});

export const { updateForgetCredentials } = forgetPasswordCredentialsSlice.actions;
export const forgetPasswordCredentialsReducer = forgetPasswordCredentialsSlice.reducer;