import React from "react";

export default function Header() {
  return (
    <section>
      <div className="flex flex-col px-5 mt-10 mb-16 mx-auto space-y-0 text-center md:space-y-6">
        <h2 className="text-2xl text-center text-darkGray md:text-4xl">
          Bienvenue sur Scholine App
        </h2>
        <h2 className="text-2xl font-bold text-center text-darkGray md:text-4xl">
          Accédez à votre espace en ligne 24/7!
        </h2>
      </div>
    </section>
  );
}
